import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import PageSeo from "../components/crumbs/pageSeo"

export default function Home() {
  // Go to the home page instead
  const refreshUrl = "/"

  return (
    <>
      <PageSeo
        title="Minden Percben | Adatvédelmi Nyilatkozat"
        description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
        keywords="hírek, érdekességek"
        canonical="https://www.mindenpercben.hu"
        canonicalMobile = "https://m.mindenpercben.hu/"        
      />
      <LayoutSite 
        refreshUrl={refreshUrl}
        content={
          <>
            <div className="section retraction" style={{ display: `block` }}>
              <div className="wrap_news_block">
                <div className="container-fluid">
                  <div className="block-part">
                    <div className="row row-glairy">
                      <div className="col-glairy col-xs-12 col-md-8">
                        <div className="row row-glairy">
                          <div className="row row-glairy">
                            <div className="col-glairy col-xs-12 col-md-12">
                              <div className="content content-hu">                             
                                <div className="content-info">
                                <h1>Adatvédelmi, Jogi nyilatkozat</h1>
                                <div className="lead" style={{ marginTop: `30px` }}>Eme oldalon a MindenPercben.hu ismeretterjesztő portál működési szerkezete, illetőleg adatvédelmi és jogi nyilatkozatai tekinthetőek meg. Forduljon hozzánk bizalommal!</div>
                                <div className="lead">Adatvédelem, Cookie-k, azaz HTTP-sütik</div>
                                <div className="lead">ADATVÉDELMI TÁJÉKOZTATÓ</div>
                                <div className="text">Ez az adatvédelmi tájékoztató a <strong>mindenpercben.hu</strong> weboldal és az azon elérhető szolgáltatások felhasználói, mint érintettek számára készült. Az adatvédelmi tájékoztató célja, hogy a <strong>Minden Percben webhely mindenkori tulajdonosi köre</strong>, a továbbiakban: "Adatkezelő") tájékoztassa az érintetteket a kapott adatok kezelésével kapcsolatban.</div>
                                </div>
                                <div className="text">
                                Tekintettel arra, hogy a szolgáltatás igénybevételéről az érintett szabadon dönthet, így a használatához szükséges adatok megadása és az adatszolgáltatás is önkéntes.
                                </div>
                                <div className="lead">1., Az adatkezelő, a kezelt adatok köre, az adatkezelés célja</div>
                                <div className="text">
                                Az adatkezelő a <strong>Minden Percben webhely mindenkori tulajdonosi köre</strong>.
                                </div>
                                <div className="text">
                                A <strong>mindenpercben.hu</strong> egyes szolgáltatásainak eléréséhez e-mailben történő kapcsolatfelvétel szükséges. Ennek során a társaság ügyfelei az általuk relevánsnak tartott adatokat adják meg amelyeket az Adatkezelő kezel.
                                </div>
                                <div className="text">
                                Amikor meglátogatja a web oldalunkat, akkor az olyan adatok, mint az IP cím (az ön gépének internetes hálózati címe), az időpont, a látogatott oldalak és más technikai adatok is naplózásra kerülnek. Mindez névtelenül történik, így az ön személyével sem most, sem a jövőben nem hozható összefüggésbe. Az adatokat nem elemezzük, kizárólag statisztikai célokat szolgálnak. Statisztikai elemzés után - amit arra használunk, hogy lássuk, hány látogatónk van és hogyan biztosíthatjuk az oldalaink megfelelően gyors működését -, az adatokat töröljük.
                                </div>
                                <div className="text">
                                Amennyiben többet szeretne tudni erről, lehetősége van írásban részletesebb tájékoztatást kérni a Szabályzat végén írt címek valamelyikén, amire feltétlenül válaszolunk.
                                </div>
                                <div className="lead">2., Az adatkezelés módja és időtartama</div>
                                <div className="text">
                                Az adatokat az Adatkezelő saját informatikai rendszerén, saját vagy bérelt szervereken valósítja meg. Az adatok ezen kívül átmenetileg az Adatkezelő egyéb számítógépeire is felkerülhetnek.
                                </div>
                                <div className="text">
                                Az adatkezelés ideje a felhasználói regisztrációtól a felhasználói regisztráció törléséig tart, egyes adatokat törvény alapján tovább is kezelhetjük (számlázási adatok, az adótörvényekre tekintettel).
                                </div>
                                <div className="text">
                                A látogatási adatok adatkezelési ideje a naplófájlok feldolgozásáig tart, amiket legfeljebb egy évig tárolunk.
                                </div>
                                <div className="lead">3., Adatvédelem</div>
                                <div className="text">
                                A kezelt személyes adatok jogosulatlan felhasználása és az azzal kapcsolatos visszaélések elkerülése érdekében Adatkezelő kiterjedt műszaki és üzemeltetési biztonsági intézkedéseket alkalmaz. Biztonsági eljárásainkat rendszeresen ellenőrizzük és a technológiai fejlődéssel összhangban fejlesztjük.
                                </div>
                                <div className="lead">4., Hozzáférés a saját adataihoz</div>
                                <div className="text">
                                Az érintett a róla tárolt adatokat bármikor teljes körűen megtekintheti a weboldal "felhasználói profil" funkciójával, ahol valamennyi adatát láthatja, módosíthatja, törölheti. Ha valami miatt ezt nem tudja elérni, akkor írhat a mindenpercben@gmail.com címre vagy tájékoztatást kérhet az Adatkezelő bármelyik elérhetőségén.
                                </div>
                                <div className="lead">5., Adattovábbítás, további címzettek</div>
                                <div className="text">
                                Az érintett adatait nem továbbítjuk harmadik félnek sem az Európai Gazdasági Közösségen belül, sem azon kívül. Egyes pénzügyi adatokat az adótörvények miatt a NAV felé kötelességünk továbbítani (számla fejadatok).
                                </div>
                                <div className="lead">6., Automatikus döntéshozatal</div>
                                <div className="text">
                                A rendszerünk automatikusan az érintett látogatási adataiból nem készít profilt, nem tesz ajánlatot és nem határoz meg kedvezményt.
                                </div>
                                <div className="lead">7., Jogérvényesítés</div>
                                <div className="text">
                                Az adatkezeléssel kapcsolatban annak bármelyik elemével kapcsolatban panaszt tehet nálunk, az adatkezelőnél az adatvédelmi tisztviselőnek címzett panasszal, amire mihamarabb válaszolunk (tisztségviselő: Kun Jeromos; e-mail cím: mindenpercben@gmail.com). Amennyiben problémáit máshol is jelezni szeretné, úgy fordulhat az Ön országa szerinti adatvédelmi hatósághoz az Európai Unión belül, vagy a Nemzeti Adatvédelmi és Információszabadság Hatóságnál (postacím: 1530 Budapest, Pf.: 5. cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c Telefon: +36 (1) 391-1400 Fax: +36 (1) 391-1410 E-mail: ugyfelszolgalat@naih.hu)
                                </div>
                                <div className="text">Budapest, 2020.</div>
                                <div className="lead" style={{ marginTop: `30px` }}>ADATVÉDELMI POLITIKA</div>
                                <div className="text">
                                A <strong>Minden Percben webhely mindenkori tulajdonosi köre</strong> elkötelezett abban, hogy az állampolgároknak, továbbá a mindenpercben.hu látogatóinak, munkavállalóinak, illetve ügyfeleinek ügyféladatait a lehető legmagasabb színvonalon és a <strong>GDPR</strong> előírásainak megfelelően kezelje. E cél elérésére adatkezelőként és adatfeldolgozóként egyaránt törekszünk.
                                </div>
                                <div className="text">
                                Munkánk során biztosítjuk a legmagasabb elérhető technikai színvonalnak megfelelő információvédelmet. Ennek érdekében adatkezelési és adatfeldolgozási tevékenységeinket a kockázatokkal arányos mértékben kialakított és működtetett, rendszeresen felülvizsgált eljárásokkal biztosítjuk. Belső szabályzataink és a folyamatainkba épített adatvédelem együttesen biztosítják, hogy munkatársaink és partnereink minden esetben tudatosan, az adatvédelmi követelményeket tiszteletben tartva járjanak el.
                                </div>
                                <div className="text">
                                Az átlátható működés szabályai szerint biztosítjuk az érintettek számára az információkérés jogának gyakorlását, valamint az általunk kezelt adataik javításához, korlátozásához való jogaik érvényesítését. Az érintettek kérhetik adataik törlését és élhetnek a tiltakozás jogával. Kérés esetén biztosítjuk az adathordozhatóság lehetőségét. Saját adatkezeléseink és a partnereink számára végzett adatfeldolgozói tevékenységünk során minden esetben rendelkezünk a megfelelő jogalappal, valamint az adatkezelési célok és határidők szerint járunk el.
                                </div>
                                <div className="text">
                                Az adatkezeléseinkről naprakész nyilvántartást vezetünk. Amennyiben szükséges, az adatkezelések megkezdése előtt kockázat-értékelést végzünk. Ennek eredménye alapján az elvárható lehető legjobb eljárást választjuk az érintettek számából és a kezelt adatok jellegéből eredő kockázatok csökkentésére.
                                </div>
                                <div className="text">
                                A <strong>Minden Percben webhely mindenkori tulajdonosi körének</strong> adatvédelemmel összefüggő tevékenységével, valamint az adatvédelmi tisztviselő elérhetőségével kapcsolatban részletes információkért látogassa meg honlapunkat: www.mindenpercben.hu.
                                </div>
                                <div className="text">Budapest, 2020.</div>
                                <div className="lead" style={{ marginTop: `30px` }}>Cookie-k, azaz "sütik" (HTTP-süti) használata</div>
                                <div className="text">
                                Weboldalunk, továbbá a webhelyünkön található bizonyos hirdetési szolgáltatók, illetőleg a honlap által használt Google termékek, szolgáltatások, Cookie-kat (sütiket) használnak/használhatnak a jobb felhasználói élmény biztosítása érdekében.
                                </div>
                                <div className="text">
                                A cookie-k olyan, kis mennyiségű információt tartalmazó szöveges fájlok, amelyeket a böngésző tárol a számítógépen a webhely meglátogatásakor. Funkciója többféle lehet, többnyire arra használják, hogy növelje a felhasználói élményt (jórészt bizonyos beállítások mentése által), illetőleg általános (nem személyes) információk nyerhetőek általa a felhasználói szokásokról. Ilyen kis fájlokat használ például a Google Analytics is. Ezen kis szöveges fájlok tehát ártalmatlanok. Ha azonban mégis szeretné letiltani a Cookie-k használatát weboldalunk esetében, akkor azt a böngészője beállításainál teheti meg, ami böngésző-fajtánként változhat.
                                </div>
                                <div className="text">
                                A Cookie-król itt tovább tájékozódhat: <a href="https://hu.wikipedia.org/wiki/HTTP-s%C3%BCti" rel="noreferrer" target="_blank">HTTP-süti</a>, angol nyelven itt: <a href="https://www.aboutcookies.org/"  rel="noreferrer" target="_blank">About Cookies</a>
                                </div>
                                <div className="lead" style={{ marginTop: `30px` }}>Cookie Policy</div>
                                <div className="text">
                                This site uses cookies, small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping carts, and provide anonymised tracking data to third party applications like Google Analytics. As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at the <a href="https://www.aboutcookies.org/" rel="noreferrer" target="_blank">About Cookies</a> website which offers guidance for all modern browsers.
                                </div>
                                <div className="lead" style={{ marginTop: `30px` }}>JOGI NYILATKOZAT</div>
                                <div className="text">
                                A mindenpercben.hu tájékoztatja Önt, hogy weboldalának megtekintése egyúttal az alábbi feltételek elfogadását is jelenti:
                                </div>
                                <div className="text">
                                A Weboldalon található minden tartalom az Üzemeltető tulajdonát képezi. Az ehhez fűződő jogok gyakorlására kizárólag a mindenpercben.hu tulajdonosa jogosult.
                                </div>
                                <div className="text">
                                Partnertől származó tartalom a weboldalon megvalósult közzétételtől számítóan jogilag az Üzemeltető tulajdonát képezi.
                                </div>
                                <div className="text">
                                A mindenpercben.hu nem vállal felelősséget olyan, harmadik fél által létrehozott, továbbított, tárolt, hozzáférhetővé tett tartalmakért, melyekhez a mindenpercben.hu weboldalai kapcsolódnak, vagy amelyekre hivatkoznak.
                                </div>
                                <div className="text">
                                Weboldalon megjelenített tartalom jogosultsága és javadalmazása az Üzemeltető egyedi döntése alapján történik, beleértve a cikkeket, véleményeket, anyagokat, stb., melyek az Üzemeltető előzetes írásbeli engedélye nélkül sem online, sem nyomtatott formában nem használható fel. A Weboldal egyes részeit Ön kizárólag saját felhasználás céljából a merevlemezére mentheti vagy kinyomtathatja, ám ebben az esetben sem válik jogosulttá a Weboldal így többszörözött részének a tovább használatára, terjesztésére, fénymásolására, átvételére, adatbázisban történő tárolására, letölthetővé tételére, a tartalom kereskedelmi forgalomba történő hozatalára.
                                </div>
                                <div className="text">
                                A partnerek által felállított egyedi hirdetési módszerekért felelősséget nem vállalunk. Amennyiben egy adott partner megszegi az online etikai kódexet úgy azon partnert a weboldalról az Üzemeltető figyelemfelhívás nélkül törölheti.
                                </div>
                                <div className="text">
                                Egyes tartalmaknál lehetőség van arra, hogy harmadik személyeknek elektronikus úton (e-mail) felhívja a figyelmét.
                                </div>
                                <div className="text">
                                Amennyiben Ön a saját weboldalán, vagy más weboldalon a Weboldalra (vagy bármelyik elemére) mutató linket, vagyis hiperhivatkozást kíván elhelyezni, azt kizárólag partneri munka céljából teheti meg.
                                </div>
                                <div className="text">
                                A hiperhivatkozásokra vonatkozó jogszabályok megsértése esetén az Üzemeltető jogosult lesz a Weboldalra mutató hiperhivatkozás azonnali eltávolítására, és a jogsértéssel felmerült kárának megtérítését követelni.
                                </div>
                                <div className="text">
                                Ön nem jogosult továbbá a lementett Weboldalról a jelen Jogi közleményekre és adatvédelmi rendelkezések elsajátítására. Ezen feltételek megsértése esetén az Üzemeltető a jogsértéssel való felhagyást és kárának megtérítését követelheti.
                                </div>
                                <div className="lead" style={{ marginTop: `30px` }}>Nem hivatalos kérés az oldal minden kedves látogatójához:</div>
                                <div className="text">
                                Az oldal elkészítésekor, fejlesztésekor, frissítésekor esetlegesen elkerülhetetlenül felhasznált anyagok, szerzőik egyediségét, készítőik nagyszerűségét dicsérik! Igyekeztünk ezeket megfelelően, jogsértést kiküszöbölve felhasználni, amennyiben ez mégsem sikerült, vagy bármely látogatónk művét véli felismerni weboldalunkon és sérelmezi megjelenését, vagy a forrás feltüntetésének hiányát, kérnénk, ez esetekben elsőként forduljon hozzánk bizalommal, a kompromisszum megteremtése és a nagyobb volumenű, kimerítő konfliktusok elkerülése érdekében.
                                </div>                                
                                <div className="lead" style={{ marginTop: `10px`, fontSize: `24px` }}>Köszönjük!</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}